/* vendor search css start */
.section-style {
  position: absolute;
  top: 90%;
  right: 5%;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 20em;
  z-index: 1000;
}

@media (max-width: 450px) {
  .section-style {
    right: auto;
    /* Reset right to avoid conflicts */
    left: 5%;
  }

  .searchbox {
    width: 100%;
  }

  .search-container {
    flex-wrap: wrap;
  }

  .filterbox {
    width: 100%;
  }

  .pagination_container {
    flex-direction: column;
  }

  .paginationMobile {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  /* vendorDetailsTable */
  .tab-content{
    display: flex;
    overflow: auto;
  }
}

.title {
  display: block;
  font-size: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

@media (max-width: 450px) {
  .title {
    display: block;
    font-size: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  .searchbox {
    width: 100% !important;
  }

  .search_input {
    width: 100% !important;
  }

  .AdsBoxMobile {
    width: 100% !important;
  }

  .popupMobile {
    width: 98% !important;
    /* right: 5% !important; */
  }

  .tabs_container .ulMobile {
    width: 100%;
    display: -webkit-box;
    /* display: flex;
    justify-content: left;
    height: 6em; */
    overflow-x: scroll;
  }
}

.filter-gray-btn .filter-ic {
  width: 18px;
  height: 10.5px;
}

.filter-sec .filter-gray-btn,
.filter-sec .filter-gray-btn:hover {
  background: #f1f5f9;
  padding: 6px 10px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: #64748b;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Regular";
  text-transform: capitalize;
  box-shadow: none;
}

.table-filter-contain .filter-gray-btn,
.table-filter-contain .filter-gray-btn:hover,
.table-filter-contain .filter-gray-btn:active,
.table-filter-contain .filter-gray-btn:focus {
  background: transparent !important;
  font-size: 16px;
  color: #081F5C !important;
  line-height: 24px;
  font-family: 'Poppins-Regular';
  gap: 8px;
}

.table-filter-contain .filter-gray-btn .filter-ic {
  color: #1E293B !important;
}

.filter-menu-main .paperpros-filter-contain,
.table-filter-menu-main .table-paperpros-filter-contain {
  min-width: 394px;
  padding: 20px;
  margin-top: 4px;
  border-radius: 8px;
  border: 1px solid #e5f4ff;
  box-shadow: 0px 8px 32px 0px rgb(0 0 0 / 6%);
}

.close-btn-filter .btn-close-footer,
.close-btn-filter .btn-close-footer:hover,
.close-btn-filter .btn-close-footer:active,
.close-btn-filter .btn-close-footer:focus {
  color: #00365c !important;
  padding: 10px 16px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  font-size: 16px;
  background-color: transparent !important;
  font-weight: 500;
  line-height: 24px;
  font-family: "Poppins-Medium";
  text-transform: capitalize;
}

.filter-submit-group .filter-reset-btn {
  background: #e2e8f0 !important;
  padding: 10px 16px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  color: #64748b !important;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: "Poppins-Medium";
  text-transform: capitalize;
}

.filter-submit-group .filter-apply-btn {
  background: #00365c !important;
  padding: 10px 16px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  color: #ffffff !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: "Poppins-Medium";
  text-transform: capitalize;
}

.filter-flex-footer {
  align-items: center;
  justify-content: space-between;
}

.filter-menu-footer::after {
  content: "";
  background: #e2e8f0;
  width: calc(100% + 40px);
  height: 1px;
  position: absolute;
  top: 0;
  left: -20px;
}

.table-filter-menu-list {
  padding: 0px;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.filter-menu-footer {
  padding: 24px 0px 4px 0px;
  position: relative;
  margin-top: 40px;
}

.filter-submit-group {
  display: flex;
  align-items: center;
  gap: 16px;
}

.mb-16px {
  margin-bottom: 16px;
}

.btn-search-icon {
  background-color: transparent !important;
}

.search-filter-relative .btn-search-icon {
  position: absolute;
  top: 4px;
  bottom: 0;
  right: 18px;
  min-width: unset;
  margin: auto;
}

.search-filter-relative {
  position: relative;
}

.input-box .search-filter-relative .form-control input {
  padding-right: 46px !important;
}

.filter-grid-modal {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}