@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "react-toastify/dist/ReactToastify.css";

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Toastify__close-button {
  background-color: white !important;
  color: black !important;
}

h2.react-datepicker__current-month {
  text-align: center;
}

/* [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  background-color: transparent !important;
  color: inherit !important
} */

.css-19aqbty-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
  background: transparent !important;
  color: black !important;
}

.css-tmrrg2-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button,
.css-1ld66rv-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button,
.css-1wchzvc-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
  background: transparent !important;
  color: black !important;
}

.css-u3u290-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition:not(
    .MuiDateRangeCalendar-dayDragging
  )
  .MuiDateRangePickerDay-dayOutsideRangeInterval {
  background: transparent !important;
  color: black !important;
}

.css-1exd0lh-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day {
  background: transparent !important;
  color: black !important;
}

.css-1588512-MuiButtonBase-root-MuiButton-root {
  background-color: transparent !important;
}

* {
  text-align: start;
  font-family: "Poppins", sans-serif !important;
  /* color: black; */
  /* color: #FDBB05; */
  /* background-color: #00365C; */
}

.datepicker {
  background-color: white;
}

* button {
  text-align: center;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

.form-group .form-control {
  font-size: 1em;
}

a {
  color: #00365c;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.bolder {
  font-weight: bold;
}

p {
  font-size: 14px;
}

.add_user .form-control,
.form-select {
  font-size: 14px;
}

.add_user input::placeholder {
  color: #94a3b8 !important;
}

/* .add_user .form-select {
  color: #94A3B8 !important;
} */

/* Apply default styles only if no custom background or color is set */
button:not([style*="background-color"]):not([style*="color"]) {
  background-color: #00365c;
  color: white;
  font-size: 0.9em !important;
  border: none;
}

/* 

button {
  background-color: #00365C !important;
  color: white !important;
  font-size: 0.9em !important;
  border: none;
} */

.table {
  border-radius: 12px;
  overflow: hidden;
}

.rounded-table {
  overflow: hidden;
  border-radius: 12px;
}

.custom-btn-close {
  background-color: transparent !important;
  border: none;
  color: #000;
  /* Change this to the color you want */
}

.Disable_option_cancel {
  background-color: #cceaff !important;
  color: #00365c !important;
  border: none;
}

.Add_User_form {
  width: 88%;
}

.add_user_label {
  font-weight: 600;
  font-size: 0.8em;
  margin: 0.5em 0;
}

.userManagement_ul li {
  cursor: pointer;
}

.userManagement_ul li:hover {
  background-color: aliceblue;
}

/* .css-1l5xwdx-MuiButtonBase-root-MuiPaginationItem-root {
  background-color: #fdbb05 !important;
} */

.pagination-main .MuiPagination-ul .MuiPaginationItem-previousNext {
  background-color: white !important;
}

.css-1l5xwdx-MuiButtonBase-root-MuiPaginationItem-root:active {
  background-color: transparent !important;
}

.user_detail_component {
  position: relative;
  z-index: 4000;
  /* Ensure the detail panel is on top of the overlay */
}

.user_detail_component .background-overlay {
  position: fixed;
  /* Fixed so it covers the whole screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 3000;
  /* Lower than the modal content */
}

.user_detail_component .background-overlay::before {
  position: fixed;
  /* Ensure this covers the whole screen */
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black */
  z-index: 3000;
  /* Sits behind the modal */
}

@media (max-width: 450px) {
  .mobileView {
    padding: 1em !important;
  }

  .mobileWidth {
    width: 100% !important;
  }

  /* header */
  .user-details {
    display: none !important;
  }

  .dash-card-grid {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .usersMobile {
    width: 100% !important;
    flex-direction: column;
    margin-bottom: 5px !important;
  }

  .socialDetailsMobile {
    display: block !important;
  }

  .vendorDetailTabsMobile {
    padding: 1em 1.5em !important;
  }

  .revenue-trend-main {
    display: block !important;
  }

  .revenue-chart-sec {
    max-width: 100% !important;
    margin-bottom: 10px;
  }

  .revenue-card-sec {
    max-width: 100% !important;
  }

  .dash-box {
    width: 100%;
  }

  .table-filter-grid {
    display: block !important;
  }

  .MuiPaper-root {
    /* left:auto; */
    min-width: 320px !important;
  }

  .booking-trend-main {
    display: block !important;
  }

  .booking-chart-grid {
    max-width: 100% !important;
    margin-bottom: 10px;
  }

  .booking-schdule-grid {
    max-width: 100% !important;
  }

  .jus-between {
    flex-wrap: wrap;
  }

  .revenue-table-search {
    width: 100%;
  }

  .search-wrapper {
    max-width: 100% !important;
    margin-bottom: 10px;
  }

  .revenue-filter-expert {
    display: contents !important;
  }

  .view-switcher {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
  }

  /* review-rating */
  .review-rating-mobile {
    padding: 1em !important;
    width: 100% !important;
    /* display: contents !important; */
  }
  .reviewMobile {
    width: 100%;
    justify-content: space-between;
  }

  .review-ratingMobile {
    width: 15% !important;
  }

  .ratingMobile {
    width: 85% !important;
  }

  /* mainpage */
  /* .white{
    background-color: white !important;
  } */
  .blur {
    filter: blur(5px);
    pointer-events: none;
  }
  .menuMobile {
    width: 75% !important;
  }

  /* service Availability */
  .service-availability-mobile{
    width: 100% !important;
  }
}

@media (min-width: 450px) {
  .menuMobile {
    width: 50% !important;
  }
  .blur {
    filter: blur(5px);
    pointer-events: none;
  }
}

.user_detail_component hr {
  color: #64748b;
}

/* table > p {
  margin: 0;
  padding: 1.5em 0em;
} */

.pagination_box span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4em;
  height: 2.4em;
  cursor: pointer;
  background-color: white;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #f0f1f3;
  margin: 0;
  padding: 0;
}

/* .tabs_container {
  background-color:  #F1F5F9; 
    height: 3em;  
} */
.tabs_container ul,
.ads_container ul {
  background-color: #f1f5f9;
  border-radius: 8px;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.2em;
  align-items: center;
  width: 81%;
  margin-bottom: 2em;
  padding-left: 0;
  height: 2.8em;
}

.ads_container ul {
  width: fit-content;
}

.tabs_container ul li p,
.ads_container ul li p {
  padding: 0.6em 0.8em;
  border-radius: 8px;
  cursor: pointer;
  /* font-size: 0.87em; */
}

.user_detail_info p {
  font-size: 0.8em;
}

.amenities input[type="checkbox"] {
  padding: 0.5em;
  margin: 0;
}

/* Add this to your CSS file */
.amenities .form-check-input:checked {
  background-color: #00365c !important;
  /* Change this to your desired color */
  border-color: #00365c !important;
}

.amenities label {
  font-size: 0.8em;
  font-weight: 400;
}

.amenities .amenities-grid {
  display: grid;
  grid-template-columns: repeat(4, 20%);
  /* 3 equal columns */
  gap: 20px;
}

.amenities .amenities-section {
  margin-bottom: 30px;
}

.amenities .additional_offering {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  /* 3 equal columns */
  gap: 20px;
}

.working-hours-container .form-check-input:checked {
  background-color: #00365c !important;
  /* Change this to your desired color */
  border-color: #00365c !important;
}

.service-detail p {
  font-size: 0.8em;
}
.working-hours-container p {
  font-size: 0.8em;
}

.collapseList .border-bottom {
  border-bottom: 0 !important;
}

.full_day .form-check-input:checked {
  background-color: #00365c !important;
  /* Change this to your desired color */
  border-color: #00365c !important;
}

.day-section .form-check-input:checked {
  background-color: #00365c !important;
  /* Change this to your desired color */
  border-color: #00365c !important;
}

.venue-rules .form-check-input:checked {
  background-color: #00365c !important;
  /* Change this to your desired color */
  border-color: #00365c !important;
}
.venue-rules label {
  font-size: 0.8em;
  font-weight: 400;
}

.red_btn {
  background-color: #fee2e2 !important;
  color: #dc2626 !important;
}

.green_btn {
  background-color: #d1fae5 !important;
  color: #059669 !important;
}

/* .vendor_details_tab .next_btn {
  width: 5em;
} */

.vendor_details_tab .tab-content {
  min-height: 30em;
}

/* .btn {
  width: 8em;
} */
.additional-info {
  margin-top: 30px;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 15px;
  width: 100%;
}

.info-item {
  display: flex;
  flex-direction: column;
}

.info-label {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

.info-value {
  font-size: 16px;
  color: #333;
}

.user-role-button {
  background-color: transparent !important;
}

.profile-edit-btn {
  background-color: #cceaff !important;
  color: #00365c !important;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.profile-edit-text {
  font-size: 16px;
}

.profile-refresh-btn {
  background: transparent !important;
  border: none;
  cursor: pointer;
  margin-right: 20px;
}

.cancel-button {
  background-color: #cceaff !important;
  color: #333;
}

.edit-preview {
  position: absolute;
  top: 40px;
  left: 0;
}

.vendor_details_tab .btn {
  width: 8em;
}

.vendor_details_tab .edit_btn {
  width: 6em;
}

.userManagement_container {
  position: relative;
}

.userManagement_container .reset_btn {
  background-color: #e2e8f0 !important;
  color: #64748b !important;
}

.review_options ul {
  background-color: white;
  border-radius: 8px;
  list-style-type: none;
  width: 100%;
  padding-left: 0;
  margin: 0.2em 0;
  cursor: pointer;
}

.review_options ul li {
  /* width: 80%; */
  margin: 0 auto;
  padding: 0.3em 1.4em;
  font-size: 0.9em;
  width: auto;
  text-wrap: nowrap;
}

.review_options ul li:hover {
  background-color: #cceaff;
  border-radius: 8px;
}

.review_dropdown {
  position: absolute;
  top: 100%;
  right: 50%;
  z-index: 1000;
  /* width: max-content; */
  border: 1px solid #e8ebed;
  border-radius: 8px;
}

.review_options {
  position: relative;
}

.green_background {
  background-color: rgb(209, 250, 229);
  color: rgb(5, 150, 105);
}

.black_background {
  background-color: gray;
  color: white;
}

.page-title {
  font-size: 24px;
  font-weight: 600;
}

.user-menu {
  display: flex;
  align-items: center;
}

.refresh-icon {
  width: 64px;
  height: 44px;
  color: #cbd5e1;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-details {
  display: flex;
  flex-direction: column;
}

.user-name {
  font-weight: 600;
}

.user-role {
  font-size: 14px;
  color: #666;
}

.dropdown-icon {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.dropdown-container {
  position: relative;
  display: inline-block;
  margin: 20px;
  align-items: left;
}

.user-dropdown {
  background-color: #ffffff;
  width: 167px;
  height: 82px;
  position: absolute;
  top: 160%;
  right: 30%;
  z-index: 50;
  padding: 4px;
  gap: 2px;
  border-radius: 8px;
  border: 1px solid #e8ebed;
}

.dropdown-item {
  width: 157px;
  height: 36px;
  display: block;
  padding: 7px 20px 9px 16px;
  text-decoration: none;
  color: #003366;
  border-radius: 8px;
}

.dropdown-item:hover {
  background-color: #cceaff;
  color: #003366;
}

.ads_management .update_vendor_offer {
  background-color: #cceaff !important;
  color: #00365c !important;
}

.new_banner_form label {
  font-weight: 500;
  text-align: start;
}

.new_banner_form input {
  border: 1px solid #e2e8f0;
}

/* input::placeholder,  textarea::placeholder,  select {
  color: #94A3B8 !important;
} */

select.selected {
  color: black !important;
}

.new_banner .preview_banner {
  color: #00365c !important;
  background-color: white !important;
}

.new_banner .cancel_banner {
  color: #00365c !important;
  background-color: #cceaff !important;
  padding: 0.5em 0.8em;
  border-radius: 8px;
}

.new_banner .submit_banner {
  margin-left: 1em;
  padding: 0.5em 0.8em;
  border-radius: 8px;
}

.custom-file-upload {
  position: relative;
}

.file-upload-input {
  display: none;
  /* Hide the default input */
}

.file-upload-label {
  display: inline-block;
  width: 100%;
  padding: 10px;
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  cursor: pointer;
  color: gray;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400 !important;
  font-size: 14px;
}

/* .file-upload-label:hover {
  background-color: #f5f5f5;
} */

.form-control::placeholder {
  color: gray;
}

.date_picker {
  width: 100%;
}

.custom-date-input {
  position: relative;
}

.custom-date-input input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding-right: 2.5em;
  /* To make space for the icon */
  font-size: 14px;
  color: #5c6c7c;
}

.custom-date-input .calendar-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #a0a0a0;
  pointer-events: none;
  /* Makes sure clicking the icon doesn't interfere with input */
}

.custom-date-input input[type="text"]:focus {
  border-color: #007bff;
  outline: none;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding-right: 2.5em;
}

.react-datepicker-wrapper {
  width: 100%;
}

input,
select,
textarea {
  color: gray !important;
}

.react-datepicker__navigation {
  background-color: transparent !important;
}

.ad_cancel_banner {
  color: #00365c !important;
  background-color: #cceaff !important;
  padding: 0.5em 0.8em;
  border-radius: 8px;
}

.vendor_table p {
  /* margin: auto !important; */
}

.service-category{
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 8px 0 #00000014;
  
}
